export default {
    /************************/
    //  Messages
    /************************/

    LOGIN_SUCCESS_MESSAGE: "Successfully logged in.",
    LOGOUT_SUCCESS_MESSAGE: "Successfully logged out.",
    SESSION_EXPIRED_MESSAGE: "Your session is expired.",
    PROMPT_LOGIN: "Please login.",
    FORBIDDEN_CONTENT: "Your dont have permission.",
    LOGIN_FAILED: "Login failed. Wrong email or password.",
    INTERNAL_SERVER_ERROR: "Server not responding, Please contact Admin.",
    COMMON_SAVE_MESSAGE: "Saved Successfully",
    COMMON_DELETE_MESSAGE: "Deleted Successfully",
    FORM_VALIDATION_ERROR: "There are items that require your attention.",
    NO_RESULT_FOUND: "No result found",
    API_REQUEST_FAILED: "Request failed",
    //Form error
    MANDATORY_FIELD_ERROR: "This field is required.",
    INVALID_EMAIL: "Invalid email format.",
    INVALID_DATE: "Accepts only DD/MM/YYYY.",
    INVALID_DATE_TIME: "Accepts only DD/MM/YYYY HH:MM.",
    INVALID_NUMBER_FIELD_ERROR: "This field accepts only numbers.",
    FILE_UPLOAD_SIZE_ERROR: "Individual file size should be less than 5 MB!",
    FILE_UPLOAD_FAILED: "File upload failed.",
    CUSTOM_MILEAGE_ERROR: "Mileage at date of Job must be greater than Last Recorded Mileage ",

    FINANCIAL_PERIOD_ERROR: "Please select a period from dropdown",

    JOB_ID_CREATION_FAILED: "Job Id creation failed",

    SERVICE_SCHEDULE_DUE_MISMATCH: "Due tolerance cannot be greater than Next Due.",
    SERVICE_SCHEDULE_FREQUENCY_DUE_MISMATCH: "Due tolerance cannot be greater than Service Due.",
    SERVICE_SCHEDULE_EMAIL_TRIGGER_ALERT: "This update will trigger notifications to Admin after saving Vehicle. Do you really want to update?",

    EMAIL_TRIGGER_ALERT: "Are you sure you want to trigger the email",
    EMAIL_TRIGGER_SUCCESS: "Email triggered successfully",
    EMAIL_TRIGGER_FAILED: "Email triggering failed",
    SUPPLIER_EMAIL_NOTFOUND: "Supplier doesn't have an email address",
    
}