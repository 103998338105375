<template>
  <v-app>
    <Layout>
      <router-view />
    </Layout>
    <BaseNotification />
    <BaseOverlayLoading 
      v-if="overlay && overlay.status" 
      :message="overlay.message"/>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Layout from "@/layouts/Layout.vue";
import { STORE } from './enums/store';
import Vue from "vue";
import common from './common';
import { SNACKBAR } from './enums/snackbar';
export default {
  name: "App",
  data: () => ({}),
  components: {
    Layout,
    BaseNotification: () => import(/* webpackPrefetch: true */ "./components/BaseNotification.vue"),
    BaseOverlayLoading: () => import(/* webpackPrefetch: true */ "./components/BaseOverlayLoading.vue"),
  },
  created() {
    this.setAppBasicFunctions();
    this.$store.dispatch(`${STORE.AUTH}/CHECK_USER_STATUS`, this.$utils);
    this.$overlay.hide();
    this.$vuetify.theme.dark = localStorage.getItem('theme') ? (localStorage.getItem('theme')==='true') : false;
  },
  computed: {
    ...mapGetters(STORE.AUTH, ["authorized"]),
    ...mapState({
      overlay: state => state[STORE.APP].overlay_loading
    }),
    utils() {
      return {
        $route: this.$route,
        $router: this.$router,
        $store: this.$store,
      };
    }
  },
  methods: {
    setAppBasicFunctions() {
      // global utilities variable
      Vue.prototype.$utils = this.utils;

      // common snackbar methods
      Vue.prototype.$toast = {
        success: (message) => common.showNotification({ message, type: SNACKBAR.SUCCESS }, this.utils),
        error: (message) => common.showNotification({ message, type: SNACKBAR.ERROR }, this.utils),
        warning: (message) => common.showNotification({ message, type: SNACKBAR.WARNING }, this.utils),
      }

      // common overlay messages
      Vue.prototype.$overlay = {
        show: (message) => this.$store.commit(`${STORE.APP}/SET_OVERLAY_LOADING`, { status: true , message }),
        hide: () => this.$store.commit(`${STORE.APP}/SET_OVERLAY_LOADING`, { status: false , message: "" })
      }
    }
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: baseFontBold;
  src: url(./assets/fonts/Sansation_Bold.ttf);
}
@font-face {
  font-family: baseFont;
  src: url(./assets/fonts/Sansation_Regular.ttf);
}
* {
  font-family: baseFontBold, sans-serif;
}

//font- overridings
.v-application [class*="text-"],
.v-application [class*="display-"],
.v-application [class*="subtitle-"],
.v-application .caption {
  font-family: baseFontBold, sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
// ::-webkit-scrollbar-track {
//   box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2),
//     0 -10px 15px rgba(255, 255, 255, 0.5), 0px 0px 0px #ffffff,
//     inset 5px 5px 5px rgba(255, 255, 255, 0.5),
//     inset -5px -5px 5px rgba(0, 0, 0, 0.1) !important;
// }
::-webkit-scrollbar-thumb {
  // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2),
  //   0 -10px 15px rgba(255, 255, 255, 0.5), 0px 0px 0px #ffffff,
  //   inset 5px 5px 5px rgba(255, 255, 255, 0.5),
  //   inset -5px -5px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px;
  // background: linear-gradient(315deg, #6200ee 0%, #61ddd3 74%);
  background: linear-gradient(315deg, #2e3192db 0%, #00aeef 74%);
}
.bg-app-background {
  background-color: $app-background-color;
}
.bg-app-base {
  background-color: $app-base-color;
}
.v-application {
  &.theme--dark{
    .border-base {
      background: rgba(255, 255, 255, 0.06);
      backdrop-filter: blur(20px);
    }
    .dark-border {
      box-shadow: $app-border-shadow-dark;
    }
  }
  &:not(.theme--dark) {
    .border-base {
      background-color: $white;
      box-shadow: $app-border-shadow;
    }
  }
} 
.border-radius-lg {
  border-radius: $app-border-radius-lg;
}
.bb-light-grey{
  border-bottom: 1px solid lightgrey
}
.bt-light-grey{
  border-top: 1px solid lightgrey
}
.vertical-align-top {
  vertical-align: top;
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.no-word-break {
  word-break: keep-all !important;
}
.nowrap {
  white-space: nowrap;
}
.break-spaces-pre {
  white-space: pre;
}
.relative {
  position: relative;
}
.capitalize {
  text-transform: capitalize;
}
.cursor-pointer,
.cursor-pointer .v-text-field > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
.rm-col-y-padding .col-12{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.label {
  opacity: 0.85;
  font-size: 0.80rem;
  position: relative;
  &.required::after {
    content: "*";
    color: red;
    font-size: 24px;
    padding-left: 3px;
    position: absolute;
  }
}
.v-btn.primary:not(.v-btn--round) {
  background-image: $app-base-gradient !important;
}
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: $app-base-light-color !important;
}
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.completed:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.completed {
  background: $success-background !important;
}
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.notified:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.notified {
  background: $notified-background !important;
}
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.edited:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.edited {
  background: $app-base-medium-color !important;
}
.trim-text .v-select__selection{
  max-width: 170px;
}
.base_stepper {
  .v-stepper__header {
    position: fixed;
    z-index: 10;
    background: inherit;
    width: 77vw;
    &.sm {
      width: 85vw;
    }
  }
  .v-stepper__items {
    padding-top: 45px;
  }
}
@media (max-width: 420px) {
  .base_stepper {
    .v-stepper__step {
      padding: 10px 16px !important;
    }
  }
}
.v-stepper__header {
  box-shadow: none !important;
  height: auto !important;
}
.v-stepper__step{
  padding: 10px 24px !important;
  &--active {
    background: $app-base-light-color !important;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width:100%;
      height: 4px;
      background-color: $app-base-color;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
.v-stepper .v-stepper__step--editable:hover {
  background: $app-base-light-color !important;
}

@media screen and (min-width: 960px) {
  .height-100-mdup {
    height: 100%;
  }
}
.v-sheet.v-card.border-base {
  background-color: $white !important;
  box-shadow: $app-border-shadow !important;
}

.v-application .primary-gradient {
  background-image: $app-base-gradient !important;
}
//overriding base css
.v-menu__content--fixed {
  z-index: 999 !important;
}

</style>
<style lang="scss">
// For AG Grid
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.ag-table {
  width: 100%;
  &.ag-theme-alpine {
    .ag-header {
      background-color: $white !important;
    }
    .ag-root-wrapper.ag-layout-normal {
      background-color: $white !important;
      box-shadow: $app-border-shadow !important;
      border: none !important;
    }
  }
}
</style>